import $ from 'jquery';
import simpleParallax from 'simple-parallax-js';

$(document).ready(function () {
  $('.parallax-img').on('lazyloaded', function () {
    var image = document.getElementsByClassName('parallax-img');
    new simpleParallax(image, {
      delay: .6,
      transition: 'cubic-bezier(0,0,0,1)',
    });
  });
});

// import external dependencies
import jquery from 'jquery';
global.$ = global.jQuery = jquery;

// import local dependencies
import Router from './util/router';
import common from './routes/common';
import home from './routes/home';
import svg4everybody from 'svg4everybody';
svg4everybody();

import picturefill from 'picturefill';
picturefill();

import './counter';
import './scroll';
import './parallax';
import './program';

import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());

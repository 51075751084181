export default {
  init() {
    // JavaScript to be fired on all pages
    var win = $(window);
    var nav = $('header'),

    pos = nav.offset().top;

    var sticky = function() {
      win.scrollTop() > pos ? nav.addClass('sticky') : nav.removeClass('sticky')
    }

    win.scroll(sticky)

    $(document).on('click', '#toggle', function() {
      $(this).toggleClass('active');
      $('body').toggleClass('nav-open');
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
